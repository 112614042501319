<template>
  <div class="wall-offers">
    <Section limiter>
      <div class="wall-offers__title" id="page-header">
        Новогодние скидки и акции в Томске
      </div>

      <div class="wall-offers__content">
        <div class="offer-card">
          <div class="offer-card__title">
            <a href="/places/1223">Пельменная «Скалка»</a>
          </div>
          <div class="offer-card__text">
            <p><strong>Акция:</strong> Скидка 10% для томичей и гостей города по промокоду «Гастрокарта».</p>
          </div>
          <div class="offer-card__address">
            <strong>Адрес:</strong> г. Томск, ул. Вершинина, 44а стр.1, 2 этаж
          </div>
          <div class="offer-card__phone">
            <strong>Телефон:</strong> 8 (3822) 22-09-69, 8 (905) 990-53-78
          </div>
        </div>

        <div class="offer-card">
          <div class="offer-card__title">
            <a href="/places/1224">Кафе «Союз»</a>
          </div>
          <div class="offer-card__text">
            <p><strong>Акция:</strong> Скидка: 10% на вечернее меню и банкетное меню. Период действия акции: с 3 по 15 января 2025 года.</p>
            <p>После посещения кафе опубликуй фото или видео у себя в соц. сетях и получи кофе в подарок. Период действия акции: с 22 ноября по 15 января 2025 года.</p>
          </div>
          <div class="offer-card__address">
            <strong>Адрес:</strong> г. Томск, проспект Комсомольский, 70, 2 этаж
          </div>
          <div class="offer-card__phone">
            <strong>Телефон:</strong> 8 (983) 235-14-41, 8 (3822) 56-22-74
          </div>
        </div>

        <div class="offer-card">
          <div class="offer-card__title">
            <a href="/places/810">Стерео-кафе «Santa Monica»</a>
          </div>
          <div class="offer-card__text">
            <p><strong>Акция:</strong> Каждый второй безалкогольный коктейль или пунш из рождественского меню бара в подарок по промокоду «каникулы». Период действия акции: с 2 по 10 января 2025 года.</p>
          </div>
          <div class="offer-card__address">
            <strong>Адрес:</strong> г. Томск, ул. Кулёва, 26
          </div>
          <div class="offer-card__phone">
            <strong>Телефон:</strong> 8 (3822) 59-70-00, 8 (913) 829-70-00
          </div>
        </div>

        <div class="offer-card">
          <div class="offer-card__title">
            <a href="https://delovkreme.ru">Кондитерская «Дело в креме»</a>
          </div>
          <div class="offer-card__text">
            <p><strong>Акция:</strong> Скидка 7 % по промокоду «Delovkreme25». Период действия акции: с 25 декабря по 10 января 2025 года.</p>
          </div>
          <div class="offer-card__address">
            <strong>Адрес:</strong> ул. Карташова, 31 Б; ул.Титова, 6 (Южные ворота)
          </div>
          <div class="offer-card__phone">
            <strong>Телефон:</strong> 8 (3822) 57-06-00 8 (913) 827-06-00
          </div>
        </div>

        <div class="offer-card">
          <div class="offer-card__title">
            <a href="/places/814">Трактир «Сибирская трапеза»</a>
          </div>
          <div class="offer-card__text">
            <p><strong>Акция:</strong> Акция «Счастливый час» с 14:00 до 15:00 к основному заказу кофе «Лесной орех» в подарок. Период действия акции: с 1 по 15 января 2025 года.</p>
          </div>
          <div class="offer-card__address">
            <strong>Адрес:</strong> г. Томск, ул. Карла Маркса, 20а
          </div>
          <div class="offer-card__phone">
            <strong>Телефон:</strong> 8 (3822) 59-50-30, 8 (3822) 51-64-78
          </div>
        </div>

        <div class="offer-card">
          <div class="offer-card__title">
            <a href="/places/1248">Ресторан «Левкада»</a>
          </div>
          <div class="offer-card__text">
            <p><strong>Акция:</strong> Постоянная скидка - 5% от общей суммы заказа, постоянная скидка - 15% при услуге «заказ на вынос».</p>
          </div>
          <div class="offer-card__address">
            <strong>Адрес:</strong> с. Парабель, ул.Советская, 85
          </div>
          <div class="offer-card__phone">
            <strong>Телефон:</strong> 8 (983) 233-46-32
          </div>
        </div>

        <div class="offer-card">
          <div class="offer-card__title">
            <a href="/places/358">Отель «Союз»</a>
          </div>
          <div class="offer-card__text">
            <div><strong>Акция:</strong> Отель «Союз». Скидка 20% на проживание по промокоду «2025». Период действия акции: с 14 декабря по 8 января 2025 года и действует на Тариф без питания.</div>
          </div>
          <div class="offer-card__address">
            <strong>Адрес:</strong> Томск,  пр. Комсомольский, 70/1
          </div>
          <div class="offer-card__phone">
            <strong>Телефон:</strong> 8 (913) 856-22-76
          </div>
        </div>

        <div class="offer-card">
          <div class="offer-card__title">
            <a href="/places/302">Отель «Африка»</a>
          </div>
          <div class="offer-card__text">
            <div><strong>Акция:</strong></div>
            <div>1. При оплате двух ночей проживания в отеле -  третья ночь в подарок. Новогодний комплимент от отеля: бутылка игристого вина.</div>
            <div>Скидка распространяется на период проживания с 25 декабря по 10 января 2025 года при прямом бронировании по телефону. Скидка предоставляется один раз за весь период проживания.</div>
            <div style="height: 10px"></div>
            <div>2. «Новогодняя ночь в отеле «Африка»: При бронировании новогодней ночи  (с 31.12 по 01.01), получайте 50% скидку на следующие сутки.</div>
            <div style="height: 10px"></div>
            <div>
              А также:
              <ul>
                <li>- Новогодний комплимент от отеля: бутылка игристого вина и коробка конфет</li>
                <li>- Для семей с детьми – подарки для детей: на выбор новогодний сладкий подарок или творческий набор</li>
                <li>- Поздний завтрак с 10:00 до 13:00</li>
                <li>- Каждый гость получает беспроигрышный лотерейный билет!</li>
                <li>- Поздний выезд до 14:00</li>
              </ul>
            </div>
          </div>
          <div class="offer-card__address">
            <strong>Адрес:</strong> г. Томск, ул. Шевченко, 45/5
          </div>
          <div class="offer-card__phone">
            <strong>Телефон:</strong> 8 (3822) 25-07-27, 8 (3822) 78-92-50
          </div>
        </div>
      </div>
    </Section>
  </div>
</template>

<script>
export default {
  name: 'NewYearOffers',
};
</script>

<style lang="scss" scoped>
$greenColor: #0eb519;
$darkGreenColor: #00a367;

.wall-offers {
  padding: 50px 0;

  &__title {
    font-size: 60px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: normal;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-top: 2rem;

    .offer-card {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1.5rem;
      border-radius: 12px;
      flex: 1 1 auto;

      &__title {
        a {
          color: $darkGreenColor;
          font-weight: bold;
          font-size: 22px;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &__text {
        line-height: 1.5;
      }

      &__address {

      }

      &__phone {

      }
    }
  }
}
</style>
